import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { SdkMessage, ClientSdkInstance } from '@solidgate/react-sdk';
import { MessageType } from '@solidgate/client-sdk-loader';

import {
    changeExclusiveOfferState,
    setHideCustomPaymentButton,
    setIsApplePayAvailable,
    setLoadingStatus,
    setIsLoadingBankCard,
    setPaymentErrorCode,
    setIsGooglePayAvailable,
} from 'redux/payment/actions';

import { BANK_CARD_INIT_TIMEOUT } from 'constants/timeoutValues';
import { PAYMENT_EVENT_NAMES, PAYMENT_TYPES_NAME_ID } from 'constants/payments';

import sentry from 'services/Sentry/SentryInstance';
import { purchaseClick, sendAnalyticProcessorFromLoad } from 'services/Analytics';

import { formatPriceForCountryWithoutCoins } from 'helpers/payment/utils';
import getErrorCustomTags from 'helpers/payment/getPaymentErrorCustomTags';

import { PaymentMethodType } from 'types/payments/validate';
import { IUseSolidHandlers } from '../types';

import { useValidatePayment } from 'hookExperiment/useValidatePayment';

const INSUFFICIENT_FUNDS_ERROR_CODE = '3.02';

const codeErrorList = [INSUFFICIENT_FUNDS_ERROR_CODE];

export function useSolidHandlers({
    currentProduct,
    paymentData,
    payment,
    setHasPaymentError,
    setHasPaymentSuccess,
}: IUseSolidHandlers) {
    const dispatch = useDispatch();
    const [cardBrand, setCardBrand] = useState<string | null>(null);

    const validatePaymentHandler = useValidatePayment();

    const handleOnError = (error: SdkMessage[MessageType.Error]) => {
        const customTags = getErrorCustomTags(error);
        const errorCode = error?.details?.code;

        if (errorCode && currentProduct) {
            dispatch(
                setPaymentErrorCode({
                    error_code: errorCode,
                    product_name: currentProduct.name,
                    currency: paymentData.currency,
                    product_price: formatPriceForCountryWithoutCoins(currentProduct.price, paymentData.currency),
                    product_code: currentProduct.product_code,
                })
            );
        }

        sentry.logError(
            error,
            SENTRY_PAYMENT,
            ERROR_LEVELS.CRITICAL,
            {
                ...(currentProduct as object),
                ...paymentData,
            },
            customTags
        );

        setTimeout(() => {
            dispatch(setHideCustomPaymentButton(false));

            payment?.initPaymentGenerator?.();
        }, BANK_CARD_INIT_TIMEOUT);
    };

    const handleOnFail = (error: SdkMessage[MessageType.Fail]) => {
        const customTags = getErrorCustomTags(error);
        const errorCode = error?.code ?? '';

        dispatch(setHideCustomPaymentButton(true));

        if (error.code && currentProduct) {
            dispatch(
                setPaymentErrorCode({
                    error_code: error.code,
                    product_name: currentProduct.name,
                    currency: paymentData.currency,
                    product_price: formatPriceForCountryWithoutCoins(currentProduct.price, paymentData.currency),
                    product_code: currentProduct.product_code,
                })
            );
        }

        setHasPaymentError(true);

        if (codeErrorList.includes(errorCode)) {
            setTimeout(() => {
                dispatch(changeExclusiveOfferState(true));
            }, BANK_CARD_INIT_TIMEOUT);
        }

        sentry.logError(
            error,
            SENTRY_PAYMENT,
            ERROR_LEVELS.CRITICAL,
            {
                ...currentProduct,
                ...paymentData,
            },
            customTags
        );

        setTimeout(() => {
            dispatch(setHideCustomPaymentButton(false));

            payment?.initPaymentGenerator?.();
        }, BANK_CARD_INIT_TIMEOUT);
    };

    const handleOnSuccess = (data: SdkMessage[MessageType.Success]) => {
        dispatch(setHideCustomPaymentButton(true));

        setHasPaymentSuccess(true);

        validatePaymentHandler(PAYMENT_TYPES_NAME_ID.CREDIT_CARD, paymentData.payment_method as PaymentMethodType, {
            ...data,
            ...(cardBrand && { brand: cardBrand }),
        });
    };

    const handleOnSubmit = (event: SdkMessage[MessageType.Submit]) => {
        dispatch(setLoadingStatus(true));

        const paymentValue = PAYMENT_EVENT_NAMES[event.entity];

        purchaseClick(paymentValue);
    };

    const handleCard = (e: SdkMessage[MessageType.Card]) => {
        setCardBrand(e.card.brand);
    };

    const handleOnReadyPaymentInstance = (form: ClientSdkInstance) => {
        dispatch(setHideCustomPaymentButton(false));
        dispatch(setIsLoadingBankCard(false));
        const customPaymentButton = document.getElementById('customSubmitButton');

        if (customPaymentButton) {
            customPaymentButton.onclick = () => form?.submit();
        }

        sendAnalyticProcessorFromLoad({ payment: 'bank_card', event_label: 'bank_card' });
    };

    const handleOnMounted = (event: SdkMessage[MessageType.Mounted]) => {
        if (event.type === 'mounted') {
            if (event.entity === 'applebtn') {
                dispatch(setIsApplePayAvailable(true));
            }

            if (event.entity === 'googlebtn') {
                dispatch(setIsGooglePayAvailable(true));
            }
        }
    };

    return {
        handleOnError,
        handleOnFail,
        handleOnSuccess,
        handleOnSubmit,
        handleCard,
        handleOnReadyPaymentInstance,
        handleOnMounted,
    };
}
